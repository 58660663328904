import React, { useEffect, useState } from 'react';

import SEO from "components/Seo";
import Header from "components/Header";
import Footer from "components/Footer";

import LayoutMain from 'components/layout/layoutMain';
import LayoutPanel from 'components/layout/layoutPanel';
import { modalContents } from 'constants/modal-content';
import { LinkModal } from 'kh-common-components';

import Link from 'components/common/Link';
import Faq from 'components/common/Faq';
import RacPlans from 'components/cards/RacPlans';
import RacNgProductHighlight from 'components/cards/RacNgProductHighlight';

import racLogo from 'images/rac/natural-gas/kleenheat-rac-logo.png';
import heroBannerSoap from 'images/rac/natural-gas/hero-banner-soap.png';
import flowmoji50Dark from 'images/rac/natural-gas/50-flowmoji-dark.svg';

import RacPage from 'pages/rac/index';

import faqDataOffer from 'constants/components/accordion-list/rac-ng-faq';

import 'styles/pages/rac-offer-ended.css';

function RacNaturalGasPage() {

    return (
        <>
            <RacPage />
        </>
    );
}

export default RacNaturalGasPage;